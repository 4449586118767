import { createBrowserRouter } from "react-router-dom";
import React, { lazy } from "react";
const App = lazy(() => import("./Pages/customer/App"));
const Home = lazy(() => import("./Pages/customer/Home"));
const SignIn = lazy(() => import("./Pages/customer/SignIn"));
const OtpVarification = lazy(() => import("./Pages/customer/OtpVarification"));
const ResetPassword = lazy(() => import("./Pages/customer/ResetPassword"));
const NewsPage = lazy(() => import("./Pages/customer/NewsPage"));
const NewsDetail = lazy(() => import("./Pages/customer/NewsDetail"));
const TagSearch = lazy(() => import("./Pages/customer/TagSearch"));
const NewsListingcoins = lazy(() =>
  import("./Pages/customer/NewsListingcoins")
);
const ListingDetails = lazy(() =>
  import("./Components/customer/ListingDetails")
);
const TopGainerLoser = lazy(() => import("./Pages/customer/TopGainerLoser"));
const TopExchange = lazy(() => import("./Pages/customer/TopExchange"));
const UpcomingProjects = lazy(() =>
  import("./Pages/customer/UpcomingProjects")
);
const ExchangeProfile = lazy(() => import("./Pages/customer/ExchangeProfile"));
const CoinProfile = lazy(() => import("./Pages/customer/CoinProfile"));
const TopInfluencer = lazy(() => import("./Pages/customer/TopInfluencer"));
const InfluncerProfile = lazy(() =>
  import("./Pages/customer/InfluncerProfile")
);
const UserProfile = lazy(() => import("./Pages/customer/User/UserProfile"));
const ChartComponent = lazy(() => import("./Components/customer/DemoChart"));
const ProfilePage = lazy(() => import("./Pages/customer/Community/ProfilePage"));
const MarketCapCalculator = lazy(() =>
  import("./utils/common/MarketCapCalculator")
);
const FeedPostDetail = lazy(() =>
  import("./Pages/customer/Community/feed/FeedPostDetail")
);
const FeedHome = lazy(() => import("./Pages/customer/Community/FeedHome"));
const Community = lazy(() => import("./Pages/customer/Community/Community"));
export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/myprofile", element: <UserProfile /> },
      { path: "/signin", element: <SignIn to="signin" /> },
      { path: "/signup", element: <SignIn to="signup" /> },
      { path: "/forget-pass", element: <SignIn to="forget-pass" /> },
      { path: "/otpSent", element: <OtpVarification /> },
      { path: "/reset-pass", element: <ResetPassword /> },
      {
        path: "/market-news",
        element: <NewsPage key="market-news" to="market-news" />,
      },
      {
        path: "/market-reports",
        element: <NewsPage key="market-reports" to="market-reports" />,
      },
      {
        path: "/market-analysis",
        element: <NewsPage key="market-analysis" to="market-analysis" />,
      },
      { path: "/articles", element: <NewsPage key="articles" to="articles" /> },
      {
        path: "/analytics",
        element: <NewsPage key="analystics" to="analytics" />,
      },
      { path: "/:page/:slug", element: <NewsDetail /> },
      {
        path: "/analytics",
        element: <NewsPage key="analystics" to="analytics" />,
      },
      { path: "/:page/:id", element: <NewsDetail /> },
      { path: "/news/tags/:tag", element: <TagSearch /> },
      { path: "/newListings", element: <NewsListingcoins /> },
      { path: "/top100", element: <ListingDetails /> },
      { path: "/top-gainer-looser", element: <TopGainerLoser /> },
      { path: "/top-100-exchange", element: <TopExchange /> },
      { path: "/upcoming-projects", element: <UpcomingProjects /> },
      { path: "/exchange-profile/:id", element: <ExchangeProfile /> },
      { path: "/coin-profile/:id", element: <CoinProfile /> },
      { path: "/top-100-influncer", element: <TopInfluencer /> },
      { path: "/top-100-influncer/:id", element: <InfluncerProfile /> },
      { path: "/chart", element: <ChartComponent /> },
      { path: "/calculator", element: <MarketCapCalculator /> },
      {
        path: "/community",
        element: <Community />,
        children: [
          { path: "/community", element: <FeedHome /> },
          { path: "/community/:id", element: <FeedPostDetail /> },
          { path: "/community/profile/:userName", element: <ProfilePage /> },
        ],
      },
    ],
  },
]);
