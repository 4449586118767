import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchFeeds = createAsyncThunk(
  "feed/fetchFeeds",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/community/getfeed-Foryou`, {
        params: { page, limit },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const createFeedPost = createAsyncThunk(
  "feed/createFeedPost",
  async (postData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/community/add-feed`,
        postData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const likeFeedPost = createAsyncThunk(
  "feed/likeFeedPost",
  async ({ feedId, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/community/feed/${feedId}/like/${userId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const unLikeFeedPost = createAsyncThunk(
  "feed/unLikeFeedPost",
  async ({ feedId, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/community/feed/${feedId}/unLike/${userId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const voteOnPoll = createAsyncThunk(
  "feed/voteOnPoll",
  async ({ feedId, optionIndex, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/community/feed-poll`, {
        feedId,
        optionIndex,
        userId,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const addComment = createAsyncThunk(
  "feed/addComment",
  async (commentData, { rejectWithValue }) => {
    try {
      const response = await axios.post( `${process.env.REACT_APP_BASE_URL}/api/community/feed/addComment`, commentData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const fetchComments = createAsyncThunk(
  "feed/fetchComments",
  async (feedId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/community/feed/${feedId}/comments`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

const initialState = {
  feeds: [],
  comments: [],
  pagination: {
    totalFeeds: 0,
    currentPage: 1,
    totalPages: 0,
  },
  status: "idle",
  error: null,
};

const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    resetFeeds(state) {
      state.feeds = [];
      state.pagination = initialState.pagination;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeeds.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFeeds.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.feeds = action.payload.feeds;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchFeeds.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });

    builder
      .addCase(createFeedPost.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createFeedPost.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.feeds.unshift(action.payload.data); 
      })
      .addCase(createFeedPost.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });

    builder.addCase(likeFeedPost.fulfilled, (state, action) => {
      const updatedFeed = action.payload.feed;
    });

    builder.addCase(unLikeFeedPost.fulfilled, (state, action) => {
      const updatedFeed = action.payload.feed;
    });

    builder.addCase(voteOnPoll.fulfilled, (state, action) => {
      const updatedPoll = action.payload.poll;
      const feedIndex = state.feeds.findIndex(
        (feed) => feed._id === action.meta.arg.feedId
      );
      if (feedIndex !== -1) {
        state.feeds[feedIndex].poll = updatedPoll;
      }
    });

    builder.addCase(addComment.fulfilled, (state, action) => {
      state.comments.push(action.payload);
    });

    builder.addCase(fetchComments.fulfilled, (state, action) => {
      state.comments = action.payload;
    });
  },
});

export const { resetFeeds } = feedSlice.actions;

export default feedSlice.reducer;
