import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchTopbarData = createAsyncThunk(
  "news/global-data",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/global-data/topbar`
    );
    return response.data;
  }
);

export const fetchNewCoinData = createAsyncThunk(
  "globalData/fetchAdditionalData",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/global-data/newlisting`
    );

    const sortedData = response.data.sort((a, b) => {
      if (a.featured === b.featured) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
      return a.featured ? -1 : 1;
    });

    return sortedData;
  }
);

const initialState = {
  topbarData: null,
  newCoinData: [],
  newListingStatus: "idle",
  topbarStatus: "idle",
  error: null,
};

const globalDataSlice = createSlice({
  name: "globalData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopbarData.pending, (state) => {
        state.topbarStatus = "loading";
      })
      .addCase(fetchTopbarData.fulfilled, (state, action) => {
        state.topbarStatus = "succeeded";
        state.topbarData = action.payload;
      })
      .addCase(fetchTopbarData.rejected, (state, action) => {
        state.topbarStatus = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchNewCoinData.pending, (state) => {
        state.newListingStatus = "loading";
      })
      .addCase(fetchNewCoinData.fulfilled, (state, action) => {
        state.newListingStatus = "succeeded";
        state.newCoinData = action.payload;
      })
      .addCase(fetchNewCoinData.rejected, (state, action) => {
        state.newListingStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectTopbar = (state) => state.globalData.topbarData;
export const selectnewCoinData = (state) => state.globalData.newCoinData;
export const selectGlobalDataStatus = (state) => state.globalData.topbarStatus;
export const selectNewListingStatus = (state) =>
  state.globalData.newListingStatus;

export default globalDataSlice.reducer;
