import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchNews = createAsyncThunk(
  "news/fetchNews",
  async ({ type, page, limit }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/news/get?type=${encodeURIComponent(
        type
      )}&page=${page}&limit=${limit}`
    );
    response.data.type = type;

    return response.data;
  }
);

const newsFetchSlice = createSlice({
  name: "news",
  initialState: {
    newsList: [],
    total: 0,
    page: 1,
    totalPages: 1,
    newsStatus: "idle", // General status
    statusByType: {}, // Status by type
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state, action) => {
        const { type } = action.meta.arg;
        state.newsStatus = "loading";
        state.statusByType[type] = "loading"; // Set status for specific type
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        const { type } = action.payload;
        state.newsStatus = "succeeded";
        state.statusByType[type] = "succeeded"; // Set status for specific type

        if(state.newsList.some(
          (existingItem) => existingItem.type === type)){

        action.payload.news.forEach((newItem) => {
          if (
            !state.newsList.some(
              (existingItem) => existingItem.slug === newItem.slug
            )
          ) {
            state.newsList.push(newItem);
          }
        });
      }else{
        state.newsList=action.payload.news;
      }
        state.total = action.payload.total;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchNews.rejected, (state, action) => {
        const { type } = action.meta.arg;
        state.newsStatus = "failed";
        state.statusByType[type] = "failed"; // Set status for specific type
        state.error = action.error.message;
      });
  },
});

export const SelectNews = (state) => state.news;
export const selectNewsList = (state) => state.news.newsList;
export const selectStatusByType = (type) => (state) =>
  state.news.statusByType[type] || "idle";

export default newsFetchSlice;
