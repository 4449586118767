import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import getTokenFromLocalStorage from "../../utils/common/getTokenFromLocalStorage";

const initialState = {
  isSignin: getTokenFromLocalStorage() ? true : false,
  token: getTokenFromLocalStorage(),
  user: JSON.parse(localStorage.getItem("user")) || null,
  status: 'idle', // For tracking API call status
  error: null,    // For error handling
};

// Thunk for fetching updated user details
export const updateUserDetails = createAsyncThunk(
  "auth/updateUserDetails",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth; // Get the token from the Redux state
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user-profile/infoById`, {
        headers: {
          Authorization: `Bearer ${token}`, // Send token for authentication
        },
      });
      return response.data.user; // Assuming API returns the user details under `user`
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch user details");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signin: (state, action) => {
      const { token, expiryTime, user } = action.payload;
      state.isSignin = true;
      state.token = token;
      state.user = user;
      localStorage.setItem("token", token);
      localStorage.setItem("tokenExpiry", expiryTime);
      localStorage.setItem("user", JSON.stringify(user));
    },
    signout: (state) => {
      state.isSignin = false;
      state.token = null;
      state.user = null;
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiry");
      localStorage.removeItem("user");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload; // Update user details
        localStorage.setItem("user", JSON.stringify(action.payload)); // Update local storage
      })
      .addCase(updateUserDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload; // Store error message
      });
  },
});

export const { signin, signout } = authSlice.actions;
export default authSlice;
