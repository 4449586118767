import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchRecomendedNews = createAsyncThunk(
  "news/fetchRecomended",
  async ({ limit, restricted }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/news/getRecomemndedNews?limit=${limit}&restricted=${restricted}`
    );

    return response.data;
  }
);

const recomendedNewsFetchSlice = createSlice({
  name: "recomendedNews",
  initialState: {
    newsList: [],
    recomndedNewsStatus: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecomendedNews.pending, (state) => {
        state.recomndedNewsStatus = "loading";
      })
      .addCase(fetchRecomendedNews.fulfilled, (state, action) => {
        state.recomndedNewsStatus = "succeeded";
        if (
          state.newsList.length === 0 ||
          action.payload.type === state.newsList[0].type
        ) {
          action.payload.news.forEach((newItem) => {
            if (
              !state.newsList.some(
                (existingItem) => existingItem.slug === newItem.slug
              )
            ) {
              state.newsList.push(newItem);
            }
          });
        } else {
          state.newsList = action.payload.news;
        }
        state.total = action.payload.total;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchRecomendedNews.rejected, (state, action) => {
        state.recomndedNewsStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const SelectRecomendedNews = (state) => state.recomendedNews;
export const selectRecomendedNewsList = (state) =>
  state.recomendedNews.newsList;

export default recomendedNewsFetchSlice;
