import { configureStore } from "@reduxjs/toolkit";
import flashSlice from "./customer/flashSlice";
import authSlice from "./customer/authSlice";
import newsFetchSlice from "./customer/newsFetchSlice";
import globalDataSlice from "./customer/globalDataSlice";
import adminAppSlice from "./admin/adminAppSlice";
import adminAuthSlice from "./admin/adminAuthSlice";
import adminNewsSlice from "./admin/adminNewsSlice";
import coinSlice from "./customer/coinsSlice";
import gainerLooserSlice from "./customer/gainerLooserSlice";
import UpcommingCryptoSlice from "./upcommingCryptoSlice";
import exchangeSlice from "./customer/exchnageSlice";
import influncerSlice from "./customer/influncerSlice";
import adsSlice from "./AdsSlice";
import recomendedNewsFetchSlice from "./customer/recomendedNewsSlice";
import feedSlice from "./CommunitySlices/FeedSlice.js";
import socialMediaSlice from "./CommunitySlices/SocialMediaSlice.js";

const indexByteStore = configureStore({
  reducer: {
    flashMessages: flashSlice.reducer,
    auth: authSlice.reducer,
    news: newsFetchSlice.reducer,
    recomendedNews: recomendedNewsFetchSlice.reducer,
    globalData: globalDataSlice,
    coinData: coinSlice,
    exchangeData: exchangeSlice,
    gainerLooser: gainerLooserSlice,
    adminApp: adminAppSlice,
    adminAuth: adminAuthSlice,
    adminNews: adminNewsSlice,
    upcommingCrypto: UpcommingCryptoSlice,
    influencers: influncerSlice,
    ads: adsSlice,
    feed: feedSlice,
    socialMedia: socialMediaSlice,
  },
});

export default indexByteStore;
