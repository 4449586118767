import React, { Suspense, lazy } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
const Header = lazy(() => import("../../Components/customer/Header"));
const Footer = lazy(() => import("../../Components/customer/Footer"));
const DefaultTitle = lazy(() => import("../../utils/DefaultTitle"));
const ScrollToTop = lazy(() => import("../../utils/customer/ScrollToTop"));

function App() {
  const location = useLocation();

  const generateTitle = (path) => {
    if (path === "/") {
      return "IndexByte";
    }

    const segments = path.split("/").filter(Boolean);

    if (segments.length > 0) {
      const lastSegment = segments[segments.length - 1];
      return lastSegment;
    }

    return "Default Title";
  };

  const title = generateTitle(location.pathname);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ScrollToTop />
      <Container className="Container">
        <DefaultTitle title={title} />
        <Header />
        <main>
          <Row>
            <Col>
              <Suspense fallback={<div>Loading ...</div>}>
                <Outlet />
              </Suspense>
            </Col>
          </Row>
        </main>
        <Footer />
      </Container>
    </Suspense>
  );
}

export default App;
