import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getUserInfo = createAsyncThunk(
  "user/getUserInfo",
  async (userName) => {
    const response = await axios.get(`/api/user/info/${userName}`);
    return response.data.user;
  }
);

export const followUser = createAsyncThunk(
  "user/followUser",
  async (userName, { getState, rejectWithValue }) => {
    const token = getState().auth.token;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/social-media/follow`,
        { userName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.relationship;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const unfollowUser = createAsyncThunk(
  "user/unfollowUser",
  async (userName, { getState, rejectWithValue }) => {
    const token = getState().auth.token;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/social-media/unfollow`,
        { userName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return { userName, success: true };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFollowers = createAsyncThunk(
  "user/getFollowers",
  async (userId) => {
    const response = await axios.get(`/api/user/followers/${userId}`);
    return response.data.followers;
  }
);

export const getFollowing = createAsyncThunk(
  "user/getFollowing",
  async (userId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/social-media/following/${userId}`
    );
    return response.data.following;
  }
);

export const getIsFollowing = createAsyncThunk(
  "user/getIsFollowing",
  async (userName, { getState }) => {
    const token = getState().auth.token;

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/social-media/isfollowing`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.isFollowing;
  }
);

export const getFeedsByUsername = createAsyncThunk(
  "user/getFeedsByUsername",
  async (username) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/social-media/feeds/${username}`
    );
    return response.data;
  }
);

const initialState = {
  user: null,
  followers: [],
  following: [],
  feeds: [],
  loading: false,
  error: null,
  isFollowing: false,
};

const socialMediaSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(followUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(followUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(followUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(unfollowUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(unfollowUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(unfollowUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getFollowers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFollowers.fulfilled, (state, action) => {
        state.loading = false;
        state.followers = action.payload;
      })
      .addCase(getFollowers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getFollowing.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFollowing.fulfilled, (state, action) => {
        state.loading = false;
        state.following = action.payload;
      })
      .addCase(getFollowing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getIsFollowing.pending, (state) => {
        state.loading = true;
      })
      .addCase(getIsFollowing.fulfilled, (state, action) => {
        state.loading = false;
        state.isFollowing = action.payload;
      })
      .addCase(getIsFollowing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getFeedsByUsername.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFeedsByUsername.fulfilled, (state, action) => {
        state.loading = false;
        state.feeds = action.payload;
      })
      .addCase(getFeedsByUsername.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default socialMediaSlice.reducer;
